<template>
  <sgv-table
    :rKey="rKey"
    :items="logs"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.createdAt">
          {{ item.createdAt | date }}
          <small>
            <div v-if="item.isBot" class="text-info">Bot</div>
            <div v-if="item.isDesktop" class="text-warning">Desktop</div>
            <div v-if="item.isMobile" class="text-success">Mobile</div>
          </small>
        </td>
        <td v-if="hidden.tag">
          {{ item.tag }}
        </td>
        <td v-if="hidden.originalUrl">
          {{ item.originalUrl }}
        </td>
        <td v-if="hidden.url">
          {{ item.url }}
        </td>
        <td v-if="hidden.query">
          {{ item.query }}
        </td>
        <td v-if="hidden.params">
          {{ item.params }}
        </td>
        <td v-if="hidden.statusCode">
          {{ item.statusCode }}
        </td>
        <td v-if="hidden.userAgent">
          {{ item.userAgent }}
        </td>
        <td v-if="hidden.platform">
          {{ item.platform }}
        </td>
        <td v-if="hidden.isMobile">
          {{ item.isMobile }}
        </td>
        <td v-if="hidden.isDesktop">
          {{ item.isDesktop }}
        </td>
        <td v-if="hidden.isBot">
          {{ item.isBot }}
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_LOG } from './graph/log'

export default {
  mixins: [retainMixin],
  props: {
    logType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `ChronowoodthLog${this.$form.capitalize(this.logType)}Detail`,
      rKey: `ChronowoodthLog${this.$form.capitalize(this.logType)}ListCategory`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'วันที่', value: 'createdAt', sort: true},
        {text: 'tag', value: 'tag', filter: true},
        {text: 'originalUrl', value: 'originalUrl'},
        {text: 'url', value: 'url'},
        {text: 'query', value: 'query'},
        {text: 'params', value: 'params'},
        {text: 'statusCode', value: 'statusCode'},
        {text: 'userAgent', value: 'userAgent'},
        {text: 'platform', value: 'platform'},
        {text: 'isMobile', value: 'isMobile'},
        {text: 'isDesktop', value: 'isDesktop'},
        {text: 'isBot', value: 'isBot'},
      ],
      filter: {
        limit: 100,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: [
          'createdAt', 'tag', 'originalUrl', 'url', 
          'query', 'params', 'statusCode',
          'userAgent', 'platform',
          'isMobile', 'isDesktop', 'isBot'
        ],
        column: null,
        search: null,
        toolbar: null,
      },
      logs: []
    }
  },
  apollo: {
    logs: {
      query () {
        return LIST_LOG(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          logType: this.logType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {
        ...v,
        ...(v.params || {})
      }
      delete filter.order
      delete filter.params
      filter.orderBy = v.order
      return filter
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
