import gql from 'graphql-tag'


const listResponse = `
  id type tag originalUrl url
  query params statusCode
  userAgent platform
  isMobile isDesktop isBot
  createdAt
`

export const LIST_LOG = (templateType) => gql`query LIST_LOG ($logType: String!, $q: ${templateType}FilterInput) {
  logs: list${templateType} (logType: $logType, q: $q) {${listResponse}}
}`